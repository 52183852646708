.custom-select-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px 0px; /* Adjust padding to accommodate the icon */
    font-size: 16px;
    border: 2px solid #4CAF50;
    border-radius: 5px;
    background-color: transparent;
    color: #333;
    cursor: pointer;
    outline: none;
    width: 50%;
    float: right;
  }
  .filter_tabs{
    padding: 5px;
    background-color: transparent;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }
  .stock_tabs{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }

  .stock_tabs1{
    padding: 10px 5px;
    background-color: transparent;
    border: 1px solid #5e17eb;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;

  }

  .stock_tabs2{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #5e17eb;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;

  }

  .stock_tabs10{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #5e17eb;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
    font-size: 11px !important;
  }

  .active1 {
    background-color: #4CAF50;
    color: white;
  }

  .stock_tabs5{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #d18925;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }

  .stock_tabs6{
    padding: 10px 15px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
  }
  .active5 {
    background-color: #d18925;
    color: white;
  }
  
  .active6 {
    background-color: #000;
    color: white;
  }
  .active2 {
    background-color: #5e17eb;
    color: white;
  }

  .custom-select:focus {
    border-color: #4CAF50;
  }
  
  .custom-select option {
    padding: 8px 10px; /* Adjust padding to make the options smaller */
    font-size: 14px; /* Adjust font size of options */
  }
  
  .custom-select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #4CAF50;
  }
  

  .range-bar-container {
    width: 100%;
    height: 5px;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    display: flex; /* Make it a flex container to position the range bars next to each other */
    align-items: center; /* Center items vertically */
  }
  
  .range-bar {
    height: 100%;
    transition: width 0.3s ease-in-out;
  }
  
  .current-position-dot {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #5e17eb;
    border-radius: 50%;
    transform: translateX(-50%); /* Center the dot horizontally */
  }

  
  
  .range-bar-labels {
    display: flex;
    justify-content: space-between;
  }

  .index-for-bar{
    width: 10px;
    height: 20px;
    margin-right: 5px;
  }
  
  .edit-portfolio-overlay {
    position: fixed;  /*Stay in place */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
    z-index: 10; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Content */
.edit-portfolio-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.portfolio-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.portfolio-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.portfolio-card-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.portfolio-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.portfolio-details {
  flex: 1;
}

.portfolio-name {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.portfolio-quantity {
  margin: 0;
  font-size: 10px !important;
  color: #888;
}

.portfolio-card-body {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.portfolio-info {
  flex: 1;
  padding-right: 10px;
}

.portfolio-info p {
  margin: 5px 0;
  font-size: 12px !important;
}

.portfolio-profit-loss {
  font-weight: bold;
}

.portfolio-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.btn1 {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
}

.btn1-edit {
  background-color: #28a745;
}

.btn1-remove {
  background-color: #dc3545;
}

.btn1-stoploss {
  background-color: #00a2ff;
}

.btn1-view {
  background-color: #ffc107;
  text-align: center;
}

.btn1:hover {
  opacity: 0.9;
}
