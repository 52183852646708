.pagination {
    display: flex;
    list-style: none;
    padding: 20px;
    padding-bottom: 60px;
    justify-content: center;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination a {
    color: #0a0a0a;
    background-color: #9c67ff; /* Background color for dark mode */
    padding: 8px 12px;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .pagination a:hover {
    background-color: #6335e0;
    color: #fff; /* Hover background color for dark mode */
  }
  
  .pagination .active a {
    background-color: #6335e0;
    color: #fff; /* Active page background color for dark mode */
  }


  .categories-list1 {
   
   background-color: #fff;
   border-radius: 15px;
  }
  
  .categories-list1 li {
    width: 18%; /* Adjust this value as needed */
    margin-bottom: 20px; /* Adjust spacing between rows */
  }
  
  .categories-box1 {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f0f0f0; /* Add your desired background color */
    border-radius: 8px;
  }
  
  .categories-box1 svg {
    width: 50px; /* Adjust icon size as needed */
    height: 50px; /* Adjust icon size as needed */
    fill: #7031fe; /* Adjust icon color as needed */
  }
  