.main-container {
  text-align: center;
  padding: 10px;
}


.describe_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Describe popup content */
.describe_popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: justify;
  width: 95%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Popup button */
.popup-button {
  background-color: #7f35fe; /* Theme color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px 0;
}

.popup-button:hover {
  background-color: #6a2fdc;
}

/* Heading styles */
.main-heading {
  color: #7f35fe; /* Theme color */
  font-size: 2.5rem;
}


.about-section {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Added padding */
  background: linear-gradient(180deg, #622cfc, #9d68ff); /* Gradient background */
  /* Added border radius for a softer look */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
}

.about-item {
  margin-bottom: 20px;
}

.about-title-head {
  font-size: 32px; /* Increased font size */
  font-weight: bold;
  color: #fff; /* Set text color to white for contrast */
  margin-bottom: 15px; /* Increased bottom margin */
}

.about-title {
  font-size: 24px;
  font-weight: bold;
  color: #fff; /* Set text color to white for contrast */
  margin-bottom: 10px;
}

.about-description {
  font-size: 16px;
  color: #fff; /* Set text color to a lighter shade for contrast */
  line-height: 1.6; /* Adjusted line height for better readability */
  padding-left: 20px; /* Added left padding */
}

.about-link{
  text-decoration: underline !important;
  color: white;
}