/* CarouselComponent.css */
.carousel-wrapper {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.carousel-wrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.carousel-track {
  display: flex;
  gap: 15px;
}

.story-item {
  text-align: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
  padding: 10px;
  width: 80px; /* Adjust as necessary */
}

.story-avatar {
  width: 80px; /* Adjust as necessary */
  height: 80px; /* Adjust as necessary */
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  border: 2px solid #5e17eb; /* Customize the border color if needed */
  /* background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888); */
  padding: 5px; /* Space between border and image */
}

.story-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.story-username {
  font-size: 12px;
  color: #000; /* Customize text color if needed */
  margin-top: 5px;
}
