/* General styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  /* Courses section styles */
  .courses-section {
    padding: 0px;
    margin-top: -10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Course card styles */
  .course-card {
    width: 100%;
    margin: 10px;
    border: 1px solid #ddd; /* Light border */
    border-radius: 10px; /* Rounded corners */
    overflow: hidden;
    cursor: pointer; /* Indicate card is clickable */
    transition: transform 0.3s ease; /* Smooth hover effect */
  }
  
  /* Hover effect for course card */
  .course-card:hover {
    transform: translateY(-5px); /* Lift the card slightly on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  }
  
  /* Course image styles */
  .course-image {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    object-fit: cover; /* Cover image area */
  }
  
  /* Course content styles */
  .course-content {
    padding: 10px;
    background-color: #fff; /* White background for content */
  }
  
  /* Course title styles */
  .course-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333; /* Dark text color */
    margin-bottom: 10px;
  }
  
  /* Course description styles */
  .course-description {
    font-size: 1rem;
    color: #555; /* Medium-dark text color */
  }
  
  /* No courses available message styles */
  .no-courses-available {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: #888; /* Light grey text color */
    padding: 20px;
  }
  